




























import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { Inject } from 'inversify-props';
import CarAlertModel from '@/modules/cars/alerts/models/car.alert.model';
import ClipText from '@/modules/common/filters/clip-text.filter';
import CarsService, { CarsServiceS } from '@/modules/cars/cars.service';
import { Checkbox } from 'element-ui';
import DocumentFiltersService, { DocumentFiltersServiceS } from '@/modules/document-filters/document-filters.service';
import Month from '@/modules/common/types/month.type';
import { RouteUtils } from '../../utils/route.util';
import CarsAlertsService, { CarsAlertsServiceS } from '../cars-alerts.service';
import VEHICLE_TYPE_FILTER from '../../constants/vehicle-type-filter.constant';

@Component({
    filters: { ClipText },
    components: { 'el-checkbox': Checkbox },
})
export default class CarsAlertItem extends Vue {
    @Inject(CarsAlertsServiceS) alertsService!: CarsAlertsService;
    @Inject(CarsServiceS) private readonly carsService!: CarsService;
    @Inject(DocumentFiltersServiceS) protected documentFiltersService!: DocumentFiltersService;

    @Prop({
        required: true,
        type: Object,
    })
    alert!: CarAlertModel;

    @Prop({
        required: true,
        type: Boolean,
        default: false,
    })
    isChecked!: boolean;

    private isAlertSelected = this.isChecked;

    @Watch('isChecked')
    handleIsCheckedChange() {
        this.isAlertSelected = this.isChecked;
    }

    transformDate(date: Date): string {
        const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
        return `${months[date.getMonth()]} ${date.getDate()}`;
    }

    async handleViewButton() {
        new Promise((resolve => {
            this.carsService.storeState.settings.isVans = false;
            setTimeout(() => {
                resolve();
            }, 2000);
        })).then(() => {
            const { path, params, sessionData } = this.alert.extData.link;
            const providers = this.alert.extData.provider.map((item: { provider: string }) => item.provider);
            const includeVan = this.alert.extData.provider.map((item: { includeVan: boolean }) => item.includeVan);
            this.documentFiltersService.storeState.settings.month = sessionData.month - 1 as Month;
            this.carsService.storeState.settings.vehicleType.filter = includeVan.includes(true) ? VEHICLE_TYPE_FILTER.INCLUDE : VEHICLE_TYPE_FILTER.WITHOUT;
            this.documentFiltersService.storeState.settings.year = sessionData.year;
            this.carsService.storeState.settings.pickUpCityCode = sessionData.location;
            this.carsService.storeState.settings.dataSource = sessionData.data_source[0] as string;
            this.carsService.storeState.settings.lor = sessionData.lor;
            this.carsService.storeState.settings.pos = sessionData.pos;
            this.carsService.storeState.settings.currentDocumentCarClasses = sessionData.carCategories;
            this.carsService.storeState.settings.currentDocumentCompetitors = providers;
            const usePath = RouteUtils.getNotificationRoute(this.$router.currentRoute?.name || '', path);
            this.$router.push({
                name: usePath,
                params: { ...params, sessionData, alertMessage: this.alert.message, alertDate: this.alert.updatedAt },
            });
            if (this.$parent && this.$parent.$parent && this.$parent.$parent.$refs.popover) {
                const popOverElement = this.$parent.$parent.$refs.popover as HTMLElement;
                popOverElement.style.visibility = 'hidden';
            }
        });
    }

    get linkText() {
        const { text } = this.alert.extData.link;
        return text;
    }

    handleClick() {
        this.alertsService.markAlertAsRead(this.alert.id);
    }

    handleDeleteAlert() {
        this.$emit('handleDeleteAlert', this.alert.id);
    }

    handleCheckChange() {
        this.$emit('alertCheckedChange', this.alert.id, this.isAlertSelected);
    }
}
